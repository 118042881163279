/*

Tooplate 2117 Infinite Loop

https://www.tooplate.com/view/2117-infinite-loop

*/

/* @import url("https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,700,900"); */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

/* App.css */
.app {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

/*POCETAK- PUBLIC DEO-LOGIN-REGISTRACIJA-GETSTARTED*/
.get-started {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
  background-image: url("../../public/new-elipse-hitfit.png");
}
.par1-getStarted {
  margin-top: 16px;
}

.hitfit-start-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.last-part-get-started {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 50px;
  align-items: center;
  flex-direction: column;
}
.button-hitfit {
  width: 343px;
  background: linear-gradient(to bottom, #ec0707, #815454);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  padding: 16px 8px 16px;
  color: #fff;
}
.par1-getStarted {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.register-part-par {
  font-size: 26px;
  font-weight: 700;
  margin-top: 50px;
  text-align: center;
  background: linear-gradient(
    to right,
    rgba(236, 7, 7, 1),
    rgba(129, 84, 84, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.label-input-1 {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
}
.tm-input-hit {
  background-color: black;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  height: 56px;
  border-radius: 4px;
  padding: 16px;
  border-color: rgba(228, 50, 45, 1);
}
.tm-input-hit ::placeholder {
  color: white !important;
  font-size: 16px;
}
.div-forgot-password {
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forgot-password-par {
  text-align: center;
  font-size: 14px;
  color: rgba(228, 50, 45, 1);
  display: inline-block;
  text-decoration-skip-ink: none;
  text-decoration: underline;
}

.register-div-button {
  padding: 0 16px 0;
}
.register-button {
  width: 100% !important;
}
::placeholder {
  color: white !important;
  font-size: 16px;
}
.label-input {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 0px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
input {
  background-color: black; /* Set a black background for the input field */
  color: white; /* Set the text color to white */
  padding: 8px;
  border: 1px solid rgba(129, 84, 84, 1);
  border-radius: 4px;
  outline: none; /* Removes the default focus outline */
}

input:focus {
  color: white; /* Keep the text color white on focus */
}

input:focus::placeholder {
  color: white !important;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
label {
  margin-bottom: 0.2rem !important;
}

.par-pass {
  color: rgba(228, 50, 45, 1);
  font-size: 14px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 18px;
  text-align: center;
}
body,
html {
  height: 100%;
  margin: 0;
  background-color: black;
}

.forgot-password {
  justify-content: space-between;
  padding: 16px;
  display: flex;
  font-size: 17px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  -webkit-text-fill-color: white !important;
  background-color: black !important;
  caret-color: white;
  font-size: 16px !important;
}

.nav-calendar-wrapper {
  justify-content: space-between;
  background-color: black;
}
.nav-date {
  font-size: 16px;
  font-weight: 400;
  color: white;
}
.history-bookings {
  background: linear-gradient(to bottom, #ec0707, #815454);
  display: flex;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  margin-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
}
.history-header-1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.circle svg {
  margin-left: -2px; /* Adjust alignment if the icon is off-center */
}
.history-header {
  color: white;

  font-weight: 700;
  font-size: 16px;
}
.circle {
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: rgba(47, 49, 50, 1);
  justify-content: center;
  align-items: center;
}
.circle svg {
  margin-left: 5px;
}

.booking-card {
  position: relative;
  gap: 5px;
  padding: 8px 0px 8px 0px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-left: 16px;
  margin-right: 10px;
  background-color: rgba(59, 59, 59, 1);
}
.booking-card-header {
  display: flex;
  gap: 3px;
  align-items: center;
}

.booking-date {
  font-size: 16px;
  font-weight: 900;
  color: white;
}
.parent-booking-card {
  border: 1px solid var(--Red-2, #ec0707);
  background: var(--Grey-4, #3b3b3b);
  position: relative;
  gap: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: rgba(59, 59, 59, 1);
}

.time-booking-card {
  font-weight: 700;
  color: "white";
  font-size: 14px;
  color: white;
}
.hour-booking-card {
  font-weight: 400;
  color: white;
  font-size: 12px;
}
.booking-type {
  font-size: 12px;
  font-weight: 400;
  color: white;
}
.booking-time {
  padding-left: 2px;
  display: flex;
  gap: 3px;
  align-items: center;
}
.booking-card-footer {
  display: flex;
  align-items: center;
}
.sidebar-icons-div {
  display: flex;
  align-items: center;
  gap: 13px;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.menu-item {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
}
.header-sidebar {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  padding-left: 17px;
  padding-right: 17px;
}
.menu {
  padding-left: 17px;
}
.profile-section {
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(236, 7, 7, 0.4) 0%,
    rgba(129, 84, 84, 0.4) 100%
  );
  padding-left: 17px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  margin-bottom: 10px;
}
.user-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: white;
}
.avatar-placeholder {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--Gray-4, #3b3b3b);

  /* SHADOW 1 */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
}
.custom-dialog-paper {
  min-width: 330px;
  padding: 20px;
  border-radius: 8px;
  background-color: #f5f5f5;
}
.custom-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dim the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it appears above other content */
}

.custom-dialog-container {
  margin-left: 30px;
  margin-right: 30px;
  padding: 26px 26px;

  border-radius: 12px;
  background: var(--Gray-4, #3b3b3b);

  /* SHADOW 1 */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  width: 400px;
}

.custom-dialog-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: #e4322d;
}

.custom-dialog-body {
  margin: 20px 0;
}

.custom-dialog-footer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
}

.custom-dialog-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: white;
}
.custom-dialog-button {
  display: flex;
  width: 104px;
  height: 40px;
  padding: 1px 35px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
}
.custom-reschedule-button {
  border-radius: 5px;
  background: var(--Red-2, linear-gradient(90deg, #ec0707 0%, #815454 100%));
  color: white;
  /* M3/Elevation Light/1 */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}
.custom-cancel-button {
  border-radius: 5px;
  border: 1px solid var(--Red-1, #e4322d);
  background: var(--Black-1, #000);
  color: var(--Red-1, #e4322d);
}

.popup-body {
  display: flex;
  width: 304px;
  padding: 25px 26px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--Gray-4, #3b3b3b);

  /* SHADOW 1 */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.custom-dialog-footer-2 {
  position: sticky;
  bottom: 0px;
  padding-top: 10px;
  background: black;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.selected-slots-div {
  background: linear-gradient(0deg, #000 84.78%, rgba(102, 102, 102, 0) 100%);
  z-index: 10000;
  position: sticky;
  bottom: 0px;
  padding-top: 10px;
  width: 100%;
  height: 108px;
  display: flex;
}

.par-popup {
  white-space: nowrap;
  color: var(--Red-1, #e4322d);
  text-align: center;
  /* HD 6 */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 26.1px */
  letter-spacing: -1px;
}
.old-appointment {
  display: flex;
  padding: 8px 16px 8px 16px;
  flex-direction: column;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Black-1, #000);
}
.header-details {
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  color: white;
}
.par-popup-details {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: white;
}
.custom-dialog-footer-1 {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: center;
  margin-top: 26px;
}
.par-popup-details-time {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: white;
}

.new-appointment {
  flex-direction: column;
  border-radius: 16px;
  background: var(--Red-2, linear-gradient(90deg, #ec0707 0%, #815454 100%));
  display: flex;
  padding: 8px 16px 8px 16px;
  align-self: stretch;
}
.status-gym {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: white;
}
.button-new {
  padding-bottom: 30px;
}
.nav-calendar-wrapper {
  border-bottom: 1px solid white; /* Bottom line */
}

.css-ynh6fk .rs__cell:not(.rs__time) {
  border-radius: 5px;
  border: 1px solid var(--Black-1, #000);
  background: var(--Black-2, #1a1a1a);
}
.css-ynh6fk {
  background-color: black;
  border-color: black !important;
}
.css-1cpvlp1 .rs__cell:not(.rs__time) {
  background-color: black;
}

.css-ynh6fk .rs__cell.rs__time {
  border-radius: 5px;
  border: 1px solid var(--Black-1, #000);
  background-color: black;
}
.rs__header {
  color: white !important;
}
.rs__cell.rs__header.rs__today_cell {
  border-radius: 5px;
  border: 1px solid var(--Red-1, #e4322d) !important;
  background: var(--Black-2, #1a1a1a);
  border-bottom: 2px solid #ccc;
  font-size: 22px !important;
}
/* .css-vkc4v8-MuiTypography-root {
  color: white !important;
} */
span.rs__cell.rs__header.rs__today_cell > div > p:first-of-type {
  font-size: 30px !important;
  color: white !important;
}
span.rs__cell.rs__header.rs__today_cell > div > p:nth-of-type(2) {
  font-size: 12px !important;
  color: white !important; /* Example: change text color */
}

span.rs__cell.rs__header > div > p:first-of-type {
  font-size: 30px !important;
  color: #f5f5f5 !important;
}
span.rs__cell.rs__header > div > p:nth-of-type(2) {
  font-size: 12px !important;
  color: #f5f5f5 !important; /* Example: change text color */
}
.parent1-card {
  border-radius: 5px !important;
}
.css-ynh6fk {
  column-gap: 3px;
  row-gap: 3px;
}
.booking-page {
  background-color: black;
}
/*KRAJ- PUBLIC DEO-LOGIN-REGISTRACIJA-GETSTARTED*/

/* General Wrapper */
.my-bookings-wrapper {
  font-family: "Roboto", Arial, sans-serif;
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header Section */
.my-bookings-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.my-bookings-back-link {
  text-decoration: none;
  margin-right: 15px;
}

.back-icon {
  color: #ff5722;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.back-icon:hover {
  transform: scale(1.1);
}

.my-bookings-title {
  font-size: 26px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Grid Layout for Cards */
.my-bookings-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

/* Empty State */
.no-bookings {
  font-size: 20px;
  color: #666;
  margin-top: 50px;
}

.edit-profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f9fc;
}

.edit-profile-card {
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.edit-profile-card h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333333;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555555;
}

.form-group input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  width: 100%;
  padding: 0.8rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Container for the reset password page */
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #f7f7f7, #ececec);
  padding: 20px;
  box-sizing: border-box;
}

/* Form container styling */
#forgotPasswordForm {
  display: block;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Box shadow for depth */
  max-width: 400px;
  width: 100%;
  text-align: center;
}

/* Heading styling */
#forgotPasswordForm h2 {
  color: #333;
  margin-bottom: 20px;
}

/* Label styling */
#forgotPasswordForm label {
  display: block;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
  text-align: left;
}

/* Input field styling */
#forgotPasswordForm input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Submit button styling */
#forgotPasswordForm input[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#forgotPasswordForm input[type="submit"]:hover {
  background-color: #45a049;
}

/* Basic styling for the popup container */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dimmed background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.3s ease-out;
}

/* Header with title and close button */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.popup-header h2 {
  font-size: 1.8rem;
  color: #333;
  margin: 0;
}

.popup-close-button {
  background: none;
  border: none;
  font-size: 1.4rem;
  color: #aaa;
  cursor: pointer;
  transition: color 0.3s ease;
}

.popup-close-button:hover {
  color: #333;
}

.appointment-details {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.appointment {
  padding: 15px;
  border-radius: 8px;
  background-color: #f4f4f4;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 48%;
}

.appointment h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 10px;
}

.appointment strong {
  font-size: 1.2rem;
  color: #333;
}

/* Footer with buttons */
.popup-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.popup-action-button {
  background-color: #4caf50; /* Default green for confirm */
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 45%;
}

.popup-action-button.cancel {
  background-color: #f44336; /* Red for cancel */
}

.popup-action-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.popup-action-button.cancel:hover {
  background-color: #d32f2f;
  transform: scale(1.05);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.appointment-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* Custom Dialog Styles */

.custom-dialog-content {
  padding: 20px 20px 10px;
  text-align: center;
  font-size: 16px;
  color: #555;
}

.custom-dialog-actions {
  justify-content: center;
}

/* MyBookings Page Styles */
.my-bookings-container {
}

.page-title {
  margin-top: 20px;
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.bookings-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.service-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #555;
}

.service-label span {
  margin-left: 10px;
  font-weight: bold;
  color: #333;
}

.no-bookings {
  text-align: center;
  font-size: 1.2rem;
  color: #777;
}

/* Icon styling */
.service-label svg {
  font-size: 1.5rem;
  color: #007bff; /* Blue color for icons */
}

.booked-part {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7px;
  height: 100%;
}
.phone-verification {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  margin: 30px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.verification-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}
.back-to-home {
  display: flex;
  align-items: center;
}

.timer {
  font-size: 18px;
  color: #ff9800;
  margin-bottom: 15px;
}

.expired {
  font-size: 18px;
  color: #f44336;
  margin-bottom: 15px;
}

.verification-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.verification-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

.verification-input:focus {
  border-color: #007bff;
}

.verification-button {
  padding: 12px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.verification-button:hover {
  background-color: #0056b3;
}

.verification-button:active {
  background-color: #003f7f;
}

.fullscreen-overlay {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.appointment-card {
  background: linear-gradient(135deg, #f7f7f7, #e3f2fd);
  border: 1px solid #b3cde0;
  border-radius: 12px;
  padding: 15px;
  position: relative;
  text-align: left;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.3s ease, background 0.3s ease;
}

.appointment-card-header {
  font-weight: 600;
  font-size: 0.95rem;
  color: #1e3a8a;
  margin-bottom: 10px;
}

.appointment-card-body {
  font-size: 1rem;
  color: #4a5568;
  line-height: 1.6;
}

.appointment-time {
  font-size: 0.8rem;
  font-style: italic;
  color: #4b6cb7;
  margin-top: 5px;
}

.close-btn {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.2s ease;
}

.appointment-popup {
  overflow: auto;
  width: 100%;
  height: 100%;
  background: black;

  animation: fadeIn 0.3s ease-in-out;
}

.appointment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}

.close-btn {
  cursor: pointer;
  transition: transform 0.3s;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.cancel-btn {
  background-color: #f44336;
  color: #fff;
}

.cancel-btn:hover {
  background-color: #d32f2f;
}

.confirm-btn {
  background-color: #4caf50;
  color: #fff;
}

.confirm-btn:hover {
  background-color: #388e3c;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.popup-title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  padding: 15px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 30px;
  color: #333;
}

.appointment-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 80%;
  overflow: auto;
  margin-bottom: 20px;
}

.appointment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

.appointment-info {
  font-size: 14px;
  color: #555;
  word-wrap: break-word;
}

.close-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #000;
  font-size: 18px;
}

.centered-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.popup-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.appointment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
}

.appointment-info {
  font-size: 0.9rem;
  color: #555;
}

.close-btn {
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
}

.cancel-btn {
  background: #ddd;
  color: #333;
}

.cancel-btn:hover {
  background: #bbb;
}

.confirm-btn {
  background: #4caf50;
  color: #fff;
}

.confirm-btn:hover {
  background: #45a044;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.cancel-btn {
  padding: 10px 20px;
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.close-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #000;
  font-size: 18px;
  margin-left: 10px;
}

.cancel-btn:hover {
  background-color: #ff4d4d;
}

.confirm-btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.selected-slots-div {
  background-color: black;
}
.confirm-btn:hover {
  background-color: #45a049;
}

.css-1cpvlp1 > span {
  height: 90px !important;
}
.css-1mdftu-MuiTypography-root {
  color: white !important;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 500 !important;
}
.css-1mdftu-MuiTypography-root {
  font-size: 10px !important;
}
.css-1cpvlp1 {
  border-width: 0px !important;
  column-gap: 3px;
  row-gap: 3px;
}
.css-1cpvlp1 .rs__cell.rs__time {
  background-color: black;
}

[data-testid="rs-wrapper"] > div > div {
  grid-template-columns: 30px repeat(6, 1fr);
  background-color: black !important;
}

.rs__cell.rs__time {
  border-width: 0px !important;
}

.css-1cpvlp1 .rs__cell:not(.rs__time) {
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid var(--Black-1, #000);
  background: var(--Black-2, #1a1a1a);
}

.css-1cpvlp1 > span > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.css-1sn4lm3-MuiTypography-root .css-1cpvlp1 > span > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rs__hover__op {
  font-size: 30px !important;
}

.css-1ui4nee-MuiPaper-root {
  background-color: #c6373c !important;
  color: white !important;
}

.booking-page-container {
  display: flex;
  background-color: #f4f6f9;
}
.css-1mdftu-MuiTypography-root {
  line-height: 1.4 !important;
}
@media screen and (min-width: 1300px) and (max-width: 1920px) {
}

.sidebar {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 15%;
  background-color: #090909;
  padding: 50px 20px 20px 20px;
  box-shadow: 3px 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #e0e0e0;
}

/* .css-ynh6fk .rs__cell {
  background: #333333!important;;

}
.css-1d8d7kz-MuiPaper-root{
  color: white!important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root{
  color: white!important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  color: white!important;

}
.css-1sn4lm3-MuiTypography-root{
  color: white;
}
.css-1cpvlp1 .rs__cell{
  background: #333333!important;
  color: white;

}

.css-1d8d7kz-MuiPaper-root{
  background: #333333!important;
  color: white;
} */
.user-role {
  font-size: 14px;
  color: #666;
}
.span-other {
  font-size: 12px;
}
.span-fully {
  position: absolute;
  top: 10px;
  right: 5px;
}

.menu {
  list-style: none;
  width: 100%;
}

.side-bar-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  background-color: #090909;
}

.calendar-container {
  flex: 1;
  padding: 40px;
  background-color: #f8f9fc;
}

/* .css-13ebml7{
  position: absolute!important;
  right: 0;
  width: 85%;
} */

/* .rs__cell{
  width: 50%!important;
} */

h2 {
  text-align: center;
  color: #333;
}

.booking-container {
  display: flex;
  gap: 20px;
}

.left-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.right-section {
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f0f8ff;
}

.date-picker-container {
  margin-bottom: 20px;
}

.date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.2s;
}

.date-picker:focus {
  border-color: #6200ee;
}

.employee-select-container {
  margin-bottom: 20px;
}

.employee-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.2s;
}

.employee-select:focus {
  border-color: #6200ee;
}

.times-container {
  margin-top: 20px;
}

.times-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.time-button {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  border-radius: 5px;
  flex: 1 1 calc(50% - 10px);
  transition: all 0.3s ease;
}

.time-button:hover {
  background-color: #6200ee;
  color: #fff;
}

.time-button.active {
  background-color: #6200ee;
  color: #fff;
}

.summary-container {
  margin-top: 20px;
}

.continue-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #6200ee;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
  width: 100%;
}

.continue-button:hover {
  background-color: #4b00c8;
}
.booking-page {
  position: absolute;
  right: 0;
  width: 85%;

  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.booking-container {
  display: flex;
  gap: 30px;
}
.calendar-container {
  max-width: 600px;
  margin: auto;
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.calendar-container h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}
/* .rs__cell>div{
  background-color: #090909!important;
} */

.fc-toolbar-title {
  font-size: 1.2em;
  font-weight: bold;
}
/* .css-12aktbw{
  width: 70%!important;
  margin: auto;
} */
.menu-button {
  font-size: 24px;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.sidebar-test {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background: #333;
  color: white;
  padding: 20px;
  transition: 0.3s ease;
}

.sidebar-test.open {
  left: 0;
}

.sidebar h2 {
  margin-top: 0;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px 0;
  cursor: pointer;
}

.arrow-menu {
  font-size: 14px;
  font-weight: bold;
}

.css-1sn4lm3-MuiTypography-root {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: var(--gray-500, #71717a);
}
.parent-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  width: 90%;
  border-radius: 8px;
}
.parent-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  width: 90%;
  border-radius: 8px;
}

.square {
  width: 20%;
  height: 40px;
}

.text-status {
  flex-grow: 1;
  text-align: center;
}

/* .number-with-line {
  width: 20%;
  display: flex;
    justify-content: center;
  color: black;
  font-size: 15px;
  text-align: right;
  position: relative;
}

.number-with-line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ccc;
  margin-left: -8px; 
} */

.css-s6u7kg {
  grid-template-columns: 7% repeat(7, 1fr) !important;
}
.css-ess3ih {
  grid-template-columns: 7% repeat(7, 1fr) !important;
}
.text-status {
  color: #090909;
  font-weight: bold;
  font-size: 9px;
}
.appointment-info {
  white-space: nowrap;
}

.calendar-icon {
  color: #090909 !important;
}

.menu-div {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.nav-calendar-wrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  /*  */
}
.calendar-icon {
  margin-right: 12px;
  font-size: 2rem;
  color: #1976d2;
}

.nav-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.parent-nav {
  background-color: white;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.icon-button {
  padding: 8px;
  border-radius: 50%;
  background-color: #e3f2fd;
  color: #1976d2;
  transition: background-color 0.3s ease;
}

.icon-button:hover {
  background-color: #bbdefb;
}

.date-text {
  font-weight: 500;
  color: #333;
  margin: 0 16px;
}

.square {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.fixed-button {
  border-radius: 10px;
  background: var(--Red-2, linear-gradient(90deg, #ec0707 0%, #815454 100%));
  position: fixed;
  bottom: 20px;
  left: 50%;
  width: 211px;
  transform: translateX(-50%);
  z-index: 1000;
  color: white;
  padding: 16px 8px;
  font-size: 16px;
  font-weight: 500;
  border: none;

  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.content {
  margin-top: 50px;
  padding: 20px;
}
.square {
  justify-content: center;
}
@media (min-width: 900) {
  .css-ynh6fk {
    grid-template-columns: 5% repeat(6, 1fr) !important;
  }
  .css-1cpvlp1 {
    grid-template-columns: 5% repeat(6, 1fr) !important;
  }
}
@media (max-width: 900px) {
  .calendar-icon {
    position: absolute;
    left: 16px;
  }

  .parent-nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
  }

  .book-appointment-div {
    color: #090909;
    font-size: 20px;
    font-weight: bold;
  }
  .square-book-now {
    display: flex;
    align-items: center;
    width: 25%;
    height: 40px;
  }
  .parent-card-book-now {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    width: 90%;
  }
}

.fixed-button-scroll {
  position: fixed;

  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #45a049;
  color: white;
  padding: 15px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* @media (min-width: 599.95px) and (max-width: 900px) {
  .number-with-line {
    font-size: 5rem;
    -webkit-text-stroke: 2px black;
    -webkit-text-fill-color: transparent;
    animation: fontsize 10s infinite;
    font-weight: bold;
  }
  .number-with-line {
    opacity: 0.4;
    font-size: 25px !important;
  }
  .square {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  .square {
    width: 25% !important;
  }
  .parent-card {
    flex-direction: row !important;
  }

  .text-status {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  .number-with-line {
    opacity: 0.4;
    font-size: 25px;
  }

  .status-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    font-weight: 700;
  }
  .text-status {
    font-size: 6px;
  }

  .menu-item {
    padding: 10px !important;
  }

  .parent-card {
    flex-direction: column;
  }
  .square {
    width: 100%;
  }
  .span-fully {
    font-size: 7px;
  }
  .span-other {
    font-size: 9px !important;
  }

  .tm-input {
    font-size: 16px;
    margin: 0px !important;
  }
  .uf_login_text {
    font-size: 22px !important;
    margin-bottom: 20px !important;
  }

  .css-1mdftu-MuiTypography-root {
    line-height: 1.2 !important;
  }
  .css-vkc4v8-MuiTypography-root {
    line-height: 1.2 !important;
  }
  .uf_login_card {
    width: 100% !important;
  }

  .close-sidebar-btn {
    top: 0;
    right: 0;
    position: absolute;
  }
  .rs__cell {
    text-align: center;
  }
  .sidebar {
    width: 90% !important;
  }

  .booking-page {
    width: 100%;
  }

  .css-s6u7kg {
    grid-template-columns: 50px repeat(7, 1fr) !important;
  }
  .css-ess3ih {
    grid-template-columns: 50px repeat(7, 1fr) !important;
  }
  .fixed-button-scroll {
    top: 30px;
  }
  .cell-status {
    font-size: 8px !important;
  }
  
  .css-s6u7kg .rs__cell.rs__time {
    -webkit-writing-mode: horizontal-tb;
    -ms-writing-mode: tb-rl;
    writing-mode: horizontal-tb !important;
  }
  .css-ynh6fk {
    grid-template-columns: 10% repeat(6, 1fr) !important;
  }
} */

.square {
  align-items: center;
  display: flex;
}
.text-status1 {
  position: absolute;
  bottom: 0; /* Align to the bottom */
  left: 0;
  right: 0;
}
.text-status1-full {
  right: 0;
  position: absolute;
  bottom: 0; /* Align to the bottom */
}

@media (max-width: 900px) {
  .reschedule-btn {
    top: unset !important;
    right: 25px !important;
  }
  .css-12aktbw {
    overflow-x: hidden;
  }
  .css-1cpvlp1 .rs__cell:not(.rs__time) {
    min-width: 50px !important;
  }
  .css-ynh6fk .rs__cell:not(.rs__time) {
    min-width: 50px !important;
  }

  .number-with-line {
    font-size: 5rem;
    -webkit-text-stroke: 2px black;
    -webkit-text-fill-color: transparent;
    animation: fontsize 10s infinite;
    font-weight: bold;
  }
  .menu-item {
    white-space: nowrap;
  }
  .fixed-button-scroll {
    top: 100px;
  }
  .square {
    width: 30% !important;
  }
  .text-status {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  .number-with-line {
    opacity: 0.4;
    font-size: 25px;
  }

  .status-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 7px;
    font-weight: 700;
  }
  .text-status {
    font-size: 6px;
  }
  .menu-item {
    padding: 10px !important;
  }

  .parent1-selected-card {
    border-radius: 8px;
    transform: scale(1.05);
    border: 3px solid gold;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 90%;
    height: 90%;
    transition: transform 0.4s ease, box-shadow 0.4s ease,
      border-color 0.4s ease, width 0.4s ease, height 0.4s ease;
  }

  .check-icon-selected {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-color: gold;
    background-color: gold;
    color: black;
    border-radius: 50%;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease,
      background-color 0.3s ease;
  }

  .number-with-line {
    font-size: 25px !important;
  }
  .parent-card {
    flex-direction: row;
  }
  .parent1-card {
    width: 100%;
    height: 100%;
    transition: box-shadow 0.3s ease, border-color 0.3s ease,
      background-color 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow */
  }
  .check-icon {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .square{
    border: unset!important;
    border-radius: unset!important;
    width: 100%!important;
    height: 20px!important;
  } */
  .span-fully {
    font-size: 7px;
  }
  .span-other {
    font-size: 9px !important;
  }

  .tm-input {
    font-size: 16px;
    margin: 0px !important;
  }
  .uf_login_text {
    font-size: 22px !important;
    margin-bottom: 20px !important;
  }

  .css-1mdftu-MuiTypography-root {
    line-height: 1.2 !important;
  }
  .css-vkc4v8-MuiTypography-root {
    line-height: 1.2 !important;
  }
  .uf_login_card {
    width: 100% !important;
  }

  .close-sidebar-btn {
    top: 0;
    right: 0;
    position: absolute;
  }
  .rs__cell {
    text-align: center;
  }
  .sidebar {
    width: 90% !important;
  }

  .booking-page {
    width: 100%;
  }

  .css-s6u7kg {
    grid-template-columns: 50px repeat(7, 1fr) !important;
  }
  .css-ess3ih {
    grid-template-columns: 50px repeat(7, 1fr) !important;
  }
  .cell-status {
    font-size: 8px !important;
  }
  .css-s6u7kg .rs__cell:not(.rs__time) {
    /* min-width: 50px!important; */
  }
  .css-s6u7kg .rs__cell.rs__time {
    -webkit-writing-mode: horizontal-tb;
    -ms-writing-mode: tb-rl;
    writing-mode: horizontal-tb !important;
    /* padding: 25px!important; */
  }
  .css-ess3ih .rs__cell:not(.rs__time) {
    /* min-width: 50px!important; */
  }
}

.fc .fc-daygrid-day-frame {
  padding: 5px;
}

.fc-timegrid-slot-label,
.fc-timegrid-slot-lane {
  font-size: 0.8em;
}

.rs__header {
  display: flex;
  justify-content: center;
}

/* .sidebar{
  background-color:#333333;
} */
body {
  padding-right: 0px !important;
}
.fc-timegrid-event {
  font-size: 0.85em;
  padding: 2px 4px;
  background-color: #42a5f5;
  color: white;
  border-radius: 4px;
}

.editor-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
  margin: 0 auto;
}

.title {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.centered-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.fc-timegrid-slot-label-cushion {
  padding: 3px 0;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 10px;
}
.css-ess3ih .rs__cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: "#70757a;";
}
.event-red {
  color: transparent;
  background-color: red !important;
  pointer-events: none;
}

.spinner-loader {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.css-1mdftu-MuiTypography-root {
  font-size: 15 !important;
}

.fc .fc-prev-button,
.fc .fc-next-button {
  font-size: 0.8em;
  padding: 5px 10px;
}

.left-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.right-section {
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.date-picker-container,
.employee-select-container {
  margin-bottom: 20px;
}

.date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.2s;
}

.date-picker:focus {
  border-color: #6200ee;
}

.employee-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.2s;
}

.employee-select:focus {
  border-color: #6200ee;
}

.times-container {
  margin-top: 20px;
}

.times-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.time-button {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  color: #000;
  cursor: pointer;
  border-radius: 5px;
  flex: 1 1 calc(50% - 10px);
  transition: all 0.3s ease;
}

.time-button:hover {
  color: #fff;
}

.time-button.active {
  background-color: #6200ee;
  color: #fff;
}

.summary-container {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #6200ee;
  border-radius: 5px;
  background-color: #f0f8ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.summary-details {
  margin-bottom: 20px;
}

.continue-button {
  padding: 10px 20px;
  background-color: #6200ee;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
  width: 100%;
  font-size: 16px;
}

.continue-button:hover {
  background-color: #4b00c8;
}

.service-selection {
  width: 60%;
}

.cart-summary {
  width: 30%;
  border: 1px solid #ddd;
  padding: 20px;
  background-color: #f9f9f9;
}

.service-item {
  margin-bottom: 20px;
}

button {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h1 {
  text-align: center;
  color: #333;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.service-list,
.selected-services {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background: #f9f9f9;
}

.service-list ul,
.selected-services ul {
  list-style-type: none;
  padding: 0;
}

.service-list li,
.selected-services li {
  margin: 10px 0;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.selected-services button {
  background-color: #dc3545;
}

.selected-services button:hover {
  background-color: #c82333;
}

body {
  font-family: "Raleway", sans-serif;
  font-size: 1.2em;
  color: #707070;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 16px;
  position: relative;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
.h2-pass {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 10px;
  margin-top: 45px;
  position: relative;
  text-align: center;
}

.alert-warning {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

.scheduler-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.centered-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.editor-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.event-appointment {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 99.5%;
  height: 100%;
  display: block;
  cursor: pointer;
  overflow: hidden;
  background-color: #1976d2;
  color: #fff;
}

/* .button:hover {
  background-color: #0056b3;
} */

.detail-container {
  margin-bottom: 15px;
}

.title {
  color: #333;
  margin-bottom: 10px;
}

.detail-text {
  margin: 5px 0;
}

.alert-info {
  background-color: #d1ecf1;
  color: #0c5460;
  border: 1px solid #bee5eb;
}

.alert-close {
  background: none;
  border: none;
  font-size: 20px;
  color: inherit;
  cursor: pointer;
  margin-left: 15px;
}
.alert-succ {
  position: absolute;
  z-index: 10000;
  width: 100%;
  height: 60px;
  overflow: hidden;
  background: #fff;
  margin: 0 auto;
  z-index: 10000;
  margin: auto;
}
.btn-login {
  margin-top: 40px;
  width: 90% !important;
}
.btn-login-forgot {
  width: 90% !important;
}
.par-pass {
  line-height: 1.5;
}
.forgot-password-div {
  margin-top: 50px;
}
.forgot-span {
  text-decoration: underline;
  cursor: pointer;
}

.register-phone {
  text-decoration: underline;
  cursor: pointer;
}

.uf_login_text {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 52px;
  margin-top: 45px;
  position: relative;
  text-align: center;
}
.uf_login_card {
  box-shadow: inset 2px 2px 4px #00000026;
  border-radius: 20px;
  background-color: #333333;
  padding-bottom: 45px;
  position: relative;
  width: 405px;
}
.uf_login {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
.uf_login_login {
  background: #f8fafb;
  border-radius: 20px;
  box-shadow: inset 2px 2px 4px #00000026;
  height: 434px;
  left: 405px;
  position: absolute;
  top: 0;
  transform: translate(-50%);
  width: 405px;
}
.buttons-div {
  display: flex;
  flex-direction: column;
}
.buttons-fit {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
a {
  transition: all 0.3s ease;
  color: #38b;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #d40;
}

a:focus {
  outline: none;
}

.btn {
  padding: 8px 32px;
}

.btn:hover {
  background-color: #ced4da;
}

blockquote {
  font-size: 0.86em;
  line-height: 1.8em;
}

.tm-section-pad-top {
  padding-top: 80px;
  padding-bottom: 40px;
}

.tm-content-box {
  padding-top: 20px;
  padding-bottom: 40px;
}

.tm-text-primary {
  color: #37a;
}

.tm-font-big {
  font-size: 1.25rem;
}

.tm-btn-primary {
  color: white;
  background-color: #369;
  padding: 14px 30px;
}

.tm-btn-primary:hover,
.tm-btn-primary:focus {
  color: white;
}

.tm-navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: transparent;
  transition: all 0.3s ease;
}

.tm-navbar.scroll {
  background-color: white;
  border-bottom: 1px solid #e9ecef;
}

.navbar-brand {
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
}

.nav-item {
  list-style: none;
}

.tm-nav-link {
  color: white;
}

.tm-navbar.scroll .tm-nav-link {
  color: #369;
}

.tm-navbar.scroll .tm-nav-link:hover,
.tm-navbar.scroll .tm-nav-link.current,
.tm-nav-link:hover {
  color: #fff;
}

.navbar-toggler {
  border: 1px solid white;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-toggler-icon {
  color: white;
  padding-top: 6px;
}

.tm-navbar.scroll .navbar-toggler {
  border: 1px solid #707070;
}

.tm-navbar.scroll .navbar-toggler-icon {
  color: #707070;
}

.logo-hitfit {
  max-width: 100px;
}
body {
  overflow: auto;
}

/* Hero */

#infinite {
  background-color: #090909;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 375px;
  position: relative;
}

@media (min-height: 600px) and (min-width: 1920px) {
  #infinite {
    background-size: cover;
  }
}

@media (min-height: 830px) {
  #infinite {
    background-position: center -210px;
  }
}

@media (min-height: 680px) and (max-height: 829px) {
  #infinite {
    background-position: center -300px;
  }
}

@media (min-height: 500px) and (max-height: 679px) {
  #infinite {
    background-position: center -400px;
  }
}

@media (max-height: 499px) {
  #infinite {
    background-position: center -450px;
  }
}

.tm-hero-text-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.tm-hero-text-container-inner {
  margin-top: -90px;
}

.tm-hero-title {
  font-size: 3.5rem;
  text-shadow: 2px 2px 2px #333;
}

.tm-hero-subtitle {
  text-shadow: 2px 2px 2px #333;
}

.tm-intro-next {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
}

@media (max-height: 480px) {
  .tm-hero-text-container-inner {
    margin-top: -40px;
  }

  .tm-intro-next {
    bottom: 20px;
  }
}

.tm-down-arrow-link {
  display: block;
  margin-top: 18%;
}

.tm-down-arrow {
  color: #fff;
  cursor: pointer;
  background: #357;
  padding: 15px 40px;
  transition: all 0.3s ease;
}

.tm-down-arrow:hover,
.tm-down-arrow:focus {
  color: #fff;
  padding: 20px 50px;
}

#introduction {
  padding-bottom: 100px;
}

.tm-section-title {
  font-size: 2.6rem;
  font-weight: normal;
}

.tm-intro-text {
  font-size: 1.2rem;
}

.tm-icon {
  display: block;
  color: #37a;
}

.tm-continue {
  padding: 20px 0 30px 0;
}

/* Testimonials */
#testimonials {
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

@media (max-width: 991px) {
  #testimonials {
  }
}

.tm-testimonials-content {
  position: relative;
  z-index: 100;
}

.tm-bg-overlay {
  width: 100%;
  height: 100%;
  background: rgba(20, 70, 80, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.tm-testimonials-carousel {
  max-width: 1050px;
  margin: 0 auto;
}

.tm-testimonial-item {
  max-width: 290px;
  margin-left: 35px;
  margin-right: 35px;
}

.tm-testimonial-item img {
  border-radius: 50%;
  margin-bottom: 35px;
}

.tm-testimonial-item figcaption {
  text-align: right;
  font-style: italic;
  font-size: 1.1rem;
}

.tm-section-desc {
  max-width: 650px;
  width: 100%;
  font-size: 0.9rem;
}

.tm-gallery-container {
  padding-top: 70px;
  padding-bottom: 120px;
}

.tm-gallery-item {
  margin: 0 15px;
}

.slick-dots {
  bottom: -65px;
}

.slick-dots li {
  margin: 0 13px;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before,
.slick-dots li.slick-active button:before {
  opacity: 1;
}

.tm-testimonials-carousel .slick-dots li button:before {
  color: white;
  opacity: 0.5;
}

.tm-testimonials-carousel .slick-dots li button:hover:before,
.tm-testimonials-carousel .slick-dots li button:focus:before,
.tm-testimonials-carousel .slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 18px;
}

.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-size: 0.9em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 600;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

figure.effect-honey {
  background: #4a3753;
  max-width: 220px;
}

figure.effect-honey img {
  opacity: 1;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-honey:hover img {
  opacity: 0.4;
}

figure.effect-honey figcaption::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: #38c;
  content: "";
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
}

figure.effect-honey h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em 1.5em;
  width: 100%;
  text-align: left;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
}

figure.effect-honey h2 i {
  font-style: normal;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
}

figure.effect-honey figcaption::before,
figure.effect-honey h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-honey:hover figcaption::before,
figure.effect-honey:hover h2,
figure.effect-honey:hover h2 i {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.tm-container-gallery {
  padding-top: 30px;
}

#contact {
  color: white;
  background-color: #001828;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 980px;
  position: relative;
  padding-bottom: 50px;
  padding-top: 100px;
}

.contact-item {
  margin-left: 20px;
  margin-bottom: 50px;
}

.item-link {
  display: flex;
  align-items: center;
}

.item-link i,
.item-link span {
  color: white;
  transition: all 0.3s ease;
}

.tm-input {
  margin: 0 0 5px 0;
  width: 100%;
  padding: 8px 20px;
  border-radius: 6px;
  border: 1px solid white;
  background: transparent;
  color: white;
}
.register-part {
  font-size: 16px;
}

.tm-btn-submit {
  font-size: 0.9em;
  color: #fff;
  background-color: #369;
  width: 50%;
  margin-bottom: 10px;
}

.tm-btn-submit:hover {
  color: #fff;
}

:-ms-input-placeholder {
  color: white;
}

::-ms-input-placeholder {
  color: white;
}

.tm-footer {
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  padding: 0 15px;
}

.tm-footer a {
  color: #fff;
}

.tm-footer a:hover {
  color: #9cf;
}

.tm-footer-link {
  color: white;
}

.tm-footer-link:hover,
.tm-footer-link:focus {
  text-decoration: none;
}

p {
  line-height: 1.9;
}

@media (min-width: 768px) {
  .tm-intro-text-container {
    padding-left: 0px;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1275px;
  }

  .tm-container-gallery {
    max-width: 1290px;
  }

  .tm-container-contact {
    max-width: 1063px;
  }
}

@media (max-width: 991px) {
  .tm-intro-text-container {
    padding-left: 15px;
    padding-top: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .tm-intro-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .tm-btn-submit {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .navbar-nav {
    max-width: 200px;
    text-align: right;
  }

  .navbar-collapse {
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 3px;
  }

  .navbar-collapse .nav-link {
    color: #707070;
    padding-right: 20px;
  }
}

@media (max-width: 480px) {
  .tm-gallery-container {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
  }

  .tm-gallery-container-2 {
    max-width: 350px;
  }

  .slick-dots li {
    margin: 0 8px;
  }

  .tm-gallery-item {
    margin: 0;
  }
}
@media (max-width: 400px) {
  .cell-status {
    font-size: 6px !important;
  }
}
